* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Work Sans', sans-serif;
}

input {
  font-family: 'Work Sans', sans-serif;
}
